import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import * as styles from '../styles/about.module.scss'

const About = ({isVertical}) =>{

    return(
        <div className = {isVertical ? styles.verticalprofile : styles.horizonprofile}>
            <div className = {styles.profilestack}>
                <div className = {styles.profileimgwrapper}>
                    <StaticImage className = {styles.profileimg}
                        src = "../images/toppage/profileimg.JPG"
                        alt = "profile img"
                        placeholder="none"
                        width={400}
                    ></StaticImage>
                </div>
                <div className = {styles.profiletextwrapper}>
                    <div className = {styles.profiletitle}>どーじょー</div>
                    <div className = {styles.profiletext}>
                        <p>大分県に３０年以上住んでいるバイク乗り。大分ツーリングはバイク・カメラで地元大分の魅力を伝えていくブログです。</p>
                        <p>ブログで情報発信をしながら、Web開発やプログラミング学習中。元高校数学教員</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About