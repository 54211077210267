import { useState } from 'react';

const useWindowWidth = () => {
    const isBrowser = typeof window !== "undefined"
    let thiswidth
    let thiswindow

    if(isBrowser){
        thiswidth = window.innerWidth
        thiswindow = window
    }
    
    const [width, setWidth] = useState(thiswidth);

    if(isBrowser){
        thiswindow.addEventListener(
            "resize",
            () => setWidth(thiswidth),
            true
        );
    }

    return width
};

export default useWindowWidth