import React from 'react'
import "@fontsource/noto-sans-jp/400.css"

import * as styles from "../styles/layout.module.scss"


import Footer from "../components/footer"
import Header from "../components/header"

const Layout = ({ location, children }) => {

  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  
  return (
    <div className = {styles.mainwrapper}>
      <Header />
      <main>
        <div className = {styles.backgroundwrapper}>
          <div className = {isRootPath ? styles.toppagebasewrapper : styles.basewrapper}>
            {children}
          </div>
        </div>
      </main>
      
      <Footer />
    </div>
  )
}

export default Layout
