import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { motion, useAnimation } from "framer-motion"
import * as styles from '../styles/createmenucontents.module.scss'
import { createLinkStyle } from './functions/createconst'
import useWindowWidth from './functions/useWindowWidth'

const CreateMenuContents = ({slug, title1, title2}) =>{

    const LinkStyle = createLinkStyle();
    const [hovered, setHovered] = useState(false);
    const control1 = useAnimation();
    const control2 = useAnimation();

    const width = useWindowWidth()

    

    let isSP = false;
    if(width < 1024){
        isSP = true;
    }if(width >= 1024){
        isSP = false;
    }

    const hoverVariants = {
        visible: {
            opacity: 1, 
            scale: 1,
            y: 0,
        },
        hidden: {
            opacity: 0, 
            y: 5,
        },
    };

    const spvisibleVariants = {
        visible: {scale:1,opacity:1},
        hidden: {scale:1,opacity:1},
    }
    const sphiddenVariants = {
        visible: {scale:0},
        hidden: {scale:0},
    }

    useEffect(() => {
        if(hovered){
            control1.start("hidden");
            control2.start("visible");
        }
        else if(!hovered){
            control1.start("visible");
            control2.start("hidden");
        }
    }
    )

    return(
        <div className = {styles.menucontentswrapper}>
            <a href={slug} className = {styles.menucontents} 
                style = {LinkStyle}
                onMouseEnter = {()=>setHovered(true)}
                onMouseLeave = {()=>setHovered(false)}
                
            >
                <motion.div className = {styles.nothover}
                    animate = {control1}
                    initial = "visible"
                    variants = {isSP ? sphiddenVariants : hoverVariants}
                >
                    {title1}
                </motion.div>
                <motion.div classNmae = {styles.hovered}
                    animate = {control2}
                    initial = "hidden"
                    variants = {isSP ? spvisibleVariants : hoverVariants}
                >
                    {title2}
                </motion.div>
            </a>
        </div>
    )
}

export default CreateMenuContents