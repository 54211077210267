import React, { useEffect, useState,} from 'react'
import { motion, useAnimation, } from "framer-motion"

import * as styles from '../styles/header.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import CreateMenuContents from './createmenucontents'
import HamburgerMenuIcon from './hambergermenuicon'
import SnsShare from './snsshare'
import useWindowWidth from './functions/useWindowWidth'

const Header = () =>{

    const width = useWindowWidth()


    let isSP = true;
    if(width < 1024){
        isSP = true;
    }if(width >= 1024){
        isSP = false;
    }

    const [isOpen, setOpen] = useState(false);

    const overspVariants = {
        hidden: {x:0,},
        visible:{x:0,},
    }

    const spmenuVariants = {
        hidden: {
            x: "-100vw",
        },
        visible: {
            x: 0,
            width: "250px",
            height: "100vh",
        }
    }
    const spmenulistVariants = {
        hidden: {
            x: "-100vw",
            width: 0,
        },
        visible: {
            x: 0,
        }
    }
    const spbaseVariants = {
        hidden: {
            x: "100vw",
            width: 0,
        },
        visible: {
            x: 0,
            width: "100vw",
        }
    }

    const control = useAnimation();
    
    useEffect(()=>{
        if(!isOpen){
            control.start("hidden");
        }
        if(isOpen){
            control.start("visible");
        }
    }
    ,[control,isOpen])


    return(
        <header>
            <div className={styles.headerwrapper}>
                <motion.div className={styles.headerlogo}
                whileHover = {{
                    scale : 1.02,
                    transition : {duration: 0.3}
                }}>
                    <a href ="/" style = {{color:"#404040",fontSize: "2.7rem",textDecoration:"none",}}>
                        <div className={styles.logowrapper}>
                            <StaticImage src = "../images/logo.png" placeholder="none" width={100}></StaticImage>
                        </div>
                    </a>
                </motion.div>

                <div className = {styles.hambergermenuiconwrapper}>
                    <HamburgerMenuIcon
                        isOpen = {isOpen}
                        onClick = {() => setOpen(!isOpen)}
                    />
                </div>


                <div className = {styles.menuoverflowwrapper}>
                    <motion.div className={styles.base}
                        animate = {control}
                        initial = "hidden"
                        variants = {isSP ? spbaseVariants : overspVariants}
                    ></motion.div>
                    <motion.div className={styles.menubase}
                        animate = {control}
                        initial = "hidden"
                        variants = {isSP ? spmenuVariants : overspVariants}
                    ></motion.div>
                    <motion.div className={styles.menuwrapper}
                        animate = {control}
                        initial = "hidden"
                        variants = {isSP ? spmenulistVariants : overspVariants}
                    >
                        <CreateMenuContents slug="/" title1="Home" title2="ホーム"></CreateMenuContents>
                        <CreateMenuContents slug="/oita/oita-city" title1="Area" title2="エリア"></CreateMenuContents>
                        <CreateMenuContents slug="/tags/" title1="Keywords" title2="キーワード"></CreateMenuContents>
                        <CreateMenuContents slug="/others" title1="Others" title2="その他"></CreateMenuContents>
                        <CreateMenuContents slug="/profile" title1="Profile" title2="プロフィール"></CreateMenuContents>
                        <CreateMenuContents slug="/form" title1="Inquiry" title2="お問い合わせ"></CreateMenuContents>
                    </motion.div>
                </div>

                <SnsShare></SnsShare>
                
            </div>

            
        </header>
    )
}

export default Header
